
<template>
  <div class="bg-stone-50 flex flex-col">
    <div
      class="border-solid border-2 border-stone-800 rounded-md my-5 p-4 mx-10"
    >
      <!-- cspell:disable-next-line -->
      <p class="mb-5 text-center font-bold">Información básica</p>
      <div class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div v-for="(info, index) in basicInfo" :key="index" :value="info">
          <label class="font-semibold">{{ info.showName }}: {{ info.text }}</label>
          <input
            v-if="info.showName == 'Nombre'"
            v-model.lazy="basicInfo[index].text"
            v-show="basicInfo[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            @change="$emit('update:name', $event.target.value)"
          />
          <input
            v-else
            v-model.lazy="basicInfo[index].text"
            v-show="basicInfo[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            @click="basicInfo[index].edit = true"
            v-show="!basicInfo[index].edit"
            class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10"
    >
      <!-- cspell:disable-next-line -->
      <p class="mb-5 text-center font-bold">Escena</p>
      <div v-for="(info, index) in scene" :key="index" :value="info">
        <label class="text-left font-semibold">{{ info.showName }}: {{ info.text }}</label>
        <textarea
          v-model.lazy="scene[index].text"
          v-show="scene[index].edit"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></textarea>
        <button @click="scene[index].edit = false" v-show="scene[index].edit"
          class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 m-2 rounded"
        >
          <!-- cspell:disable-next-line -->
          Guardar
        </button>
        <button
          @click="scene[index].edit = true"
          v-show="!scene[index].edit"
          class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
      </div>
    </div>

    <div
      class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10"
    >
      <!-- cspell:disable-next-line -->
      <h2 class="mb-5 text-center font-bold">Evaluación primaria</h2>

      <div class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div
          v-for="(info, index) in primaryAssessment"
          :key="index"
          :value="info"
        >
          <p>{{ info.showName }}: {{ info.text }}</p>
          <input
            v-model.lazy="primaryAssessment[index].text"
            v-show="primaryAssessment[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            @click="primaryAssessment[index].edit = true"
            v-show="!primaryAssessment[index].edit"
            class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10">
      <!-- cspell:disable-next-line -->
      <h2 class="mb-5 text-center font-bold">SAMPUE</h2>
      <div class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div v-for="(info, index) in sample" :key="index" :value="info">
          <p>{{ info.showName }}: {{ info.text }}</p>
          <input
            v-model.lazy="sample[index].text"
            v-show="sample[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            @click="sample[index].edit = true"
            v-show="!sample[index].edit"
            class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10">
      <!-- cspell:disable-next-line -->
      <h2 class="mb-5 text-center font-bold">Baterías de sigos vitales</h2>
      
      <div class="table-auto overflow-x-scroll">
        <table id="vitalSignRegistry" class="w-full">
          <thead>
            <tr>
              <th v-for="attribute in vitalSigns" :key="attribute">
                {{ attribute.showName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in vitalSignsRegistry" :key="group">
              <th v-for="sign in group" :key="sign">{{ sign.text }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- cspell:disable-next-line -->
      <button @click="VitalSignsView = true" v-show="!VitalSignsView" class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 m-2 rounded">Agregar signos vitales</button>
      <div v-show="VitalSignsView" class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div v-for="(info, index) in vitalSigns" :key="index" :value="info">
          <p>{{ info.showName }}: {{ info.text }}</p>
          <input
            v-model.lazy="vitalSigns[index].text"
            v-show="vitalSigns[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            @click="vitalSigns[index].edit = true"
            v-show="!vitalSigns[index].edit"
            class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>

      <div v-show="VitalSignsView">
        <!-- cspell:disable-next-line -->
      <button @click="updateRegistry()" class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 m-2 rounded">Guardar registro</button>
      <!-- cspell:disable-next-line -->
      <button @click="VitalSignsView = false" class="bg-red-300 hover:bg-red-400 text-black font-bold py-2 px-4 m-2 rounded">Cancelar</button>
      </div>
    </div>

    <div class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10">
      <!-- cspell:disable-next-line -->
      <h2 class="mb-5 text-center font-bold">Examen físico</h2>
      <div class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div
          v-for="(info, index) in physicalExamination"
          :key="index"
          :value="info"
        >
          <p>{{ info.showName }}: {{ info.text }}</p>
          <input
            v-model.lazy="physicalExamination[index].text"
            v-show="physicalExamination[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            @click="physicalExamination[index].edit = true"
            v-show="!physicalExamination[index].edit"
            class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10">
      <!-- cspell:disable-next-line -->
      <h2 class="mb-5 text-center font-bold">Plan</h2>
      <div class="grid grid-flow-row gap-4 phone:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3">
        <div v-for="(info, index) in plan" :key="index" :value="info">
          <p>{{ info.showName }}: {{ info.text }}</p>
          <textarea
            v-model.lazy="plan[index].text"
            v-show="plan[index].edit"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          ></textarea>
          <button @click="plan[index].edit = false" v-show="plan[index].edit"
            class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 m-2 rounded"
          >
            <!-- cspell:disable-next-line -->
            Guardar
          </button>
          <button
          @click="plan[index].edit = true"
          v-show="!plan[index].edit"
          class="bg-stone-50 hover:bg-stone-400 text-white font-bold py-2 px-2 m-2 rounded"
          >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " style="fill: black"/>
                  <rect fill="black" opacity="1" x="5" y="20" width="15" height="2" rx="1"
                />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="border-solid border-2 border-stone-800 rounded-md mb-5 p-4 mx-10">
      <!-- cspell:disable-next-line -->
      <button @click="createDocument()" class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 m-2 rounded">Generar documento</button>
      <!-- cspell:disable-next-line -->
      <button @click="deleteLocalStorage()" class="bg-red-300 hover:bg-red-400 text-black font-bold py-2 px-4 m-2 rounded">Borrar datos</button>
    </div>
  </div>
</template>


<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "SoapForm",
  data() {
    return {
      VitalSignsView: false,
      basicInfo: {
        name: this.genericData("Nombre"), // cspell:disable-line
        rut: this.genericData("RUT"),
        age: this.genericData("Edad"), // cspell:disable-line
        contact: this.genericData("Contacto"), // cspell:disable-line
        contactPhone: this.genericData("Teléfono contacto"), // cspell:disable-line
        datetime: this.genericData("Fecha"), // cspell:disable-line
      },
      scene: {
        scene: this.genericData("Escena"), // cspell:disable-line
      },
      primaryAssessment: {
        X: this.genericData("X"),
        A: this.genericData("A"),
        B: this.genericData("B"),
        C: this.genericData("C"),
        D: this.genericData("D"),
        E: this.genericData("E"),
      },
      sample: {
        S: this.genericData("S - Signos y síntomas"), // cspell:disable-line
        A: this.genericData("A - Alergias"), // cspell:disable-line
        M: this.genericData("M - Medicamentos"), // cspell:disable-line
        P: this.genericData("P - Pasado médico"), // cspell:disable-line
        L: this.genericData("U - Última ingesta"), // cspell:disable-line
        E: this.genericData("E - Estado anterior"), // cspell:disable-line
      },
      vitalSigns: this.vitalSignsData(),
      vitalSignsRegistry: [],
      physicalExamination: {
        head: this.genericData("Cabeza"), // cspell:disable-line
        neck: this.genericData("Cuello"), // cspell:disable-line
        chest: this.genericData("Tórax"), // cspell:disable-line
        abdomen: this.genericData("Abdomen"),
        pelvis: this.genericData("Pelvis"),
        column: this.genericData("Columna"), // cspell:disable-line
        upperExtremities: this.genericData("EESS"), // cspell:disable-line
        lowerExtremities: this.genericData("EEII"), // cspell:disable-line
      },
      plan: {
        analysis: this.genericData("Análisis"), // cspell:disable-line
        plan: this.genericData("Plan"),
      },
    };
  },
  props: ["id", "name"],
  emits: ["update:name"],
  watch: {
    basicInfo: {
      handler() {
        localStorage.setItem(
          `basicInfo-${this.id}`,
          JSON.stringify(this.basicInfo)
        );
      },
      deep: true,
    },
    scene: {
      handler() {
        localStorage.setItem(`scene-${this.id}`, JSON.stringify(this.scene));
      },
      deep: true,
    },
    primaryAssessment: {
      handler() {
        localStorage.setItem(
          `primaryAssessment-${this.id}`,
          JSON.stringify(this.primaryAssessment)
        );
      },
      deep: true,
    },
    sample: {
      handler() {
        localStorage.setItem(`sample-${this.id}`, JSON.stringify(this.sample));
      },
      deep: true,
    },
    vitalSigns: {
      handler() {
        localStorage.setItem(
          `vitalSigns-${this.id}`,
          JSON.stringify(this.vitalSigns)
        );
      },
      deep: true,
    },
    vitalSignsRegistry: {
      handler() {
        localStorage.setItem(
          `vitalSignsRegistry-${this.id}`,
          JSON.stringify(this.vitalSignsRegistry)
        );
      },
      deep: true,
    },
    physicalExamination: {
      handler() {
        localStorage.setItem(
          `physicalExamination-${this.id}`,
          JSON.stringify(this.physicalExamination)
        );
      },
      deep: true,
    },
    plan: {
      handler() {
        localStorage.setItem(`plan-${this.id}`, JSON.stringify(this.plan));
      },
      deep: true,
    },
    "basicInfo.name.text"() {
      this.basicInfo.name.edit = false;
    },
    "basicInfo.rut.text"() {
      this.basicInfo.rut.edit = false;
    },
    "basicInfo.age.text"() {
      this.basicInfo.age.edit = false;
    },
    "basicInfo.contact.text"() {
      this.basicInfo.contact.edit = false;
    },
    "basicInfo.contactPhone.text"() {
      this.basicInfo.contactPhone.edit = false;
    },
    "basicInfo.datetime.text"() {
      this.basicInfo.datetime.edit = false;
    },
    "scene.scene.text"() {
      this.scene.scene.edit = false;
    },
    "primaryAssessment.X.text"() {
      this.primaryAssessment.X.edit = false;
    },
    "primaryAssessment.A.text"() {
      this.primaryAssessment.A.edit = false;
    },
    "primaryAssessment.B.text"() {
      this.primaryAssessment.B.edit = false;
    },
    "primaryAssessment.C.text"() {
      this.primaryAssessment.C.edit = false;
    },
    "primaryAssessment.D.text"() {
      this.primaryAssessment.D.edit = false;
    },
    "primaryAssessment.E.text"() {
      this.primaryAssessment.E.edit = false;
    },
    "sample.S.text"() {
      this.sample.S.edit = false;
    },
    "sample.A.text"() {
      this.sample.A.edit = false;
    },
    "sample.M.text"() {
      this.sample.M.edit = false;
    },
    "sample.P.text"() {
      this.sample.P.edit = false;
    },
    "sample.L.text"() {
      this.sample.L.edit = false;
    },
    "sample.E.text"() {
      this.sample.E.edit = false;
    },
    "vitalSigns.hour.text"() {
      this.vitalSigns.hour.edit = false;
    },
    "vitalSigns.heartRate.text"() {
      this.vitalSigns.heartRate.edit = false;
    },
    "vitalSigns.respiratoryRate.text"() {
      this.vitalSigns.respiratoryRate.edit = false;
    },
    "vitalSigns.bloodPressure.text"() {
      this.vitalSigns.bloodPressure.edit = false;
    },
    "vitalSigns.temperature.text"() {
      this.vitalSigns.temperature.edit = false;
    },
    "vitalSigns.oxygenSaturation.text"() {
      this.vitalSigns.oxygenSaturation.edit = false;
    },
    "vitalSigns.consciousLevel.text"() {
      this.vitalSigns.consciousLevel.edit = false;
    },
    "vitalSigns.glycemia.text"() {
      this.vitalSigns.glycemia.edit = false;
    },
    "vitalSigns.pain.text"() {
      this.vitalSigns.pain.edit = false;
    },
    "physicalExamination.head.text"() {
      this.physicalExamination.head.edit = false;
    },
    "physicalExamination.neck.text"() {
      this.physicalExamination.neck.edit = false;
    },
    "physicalExamination.chest.text"() {
      this.physicalExamination.chest.edit = false;
    },
    "physicalExamination.abdomen.text"() {
      this.physicalExamination.abdomen.edit = false;
    },
    "physicalExamination.pelvis.text"() {
      this.physicalExamination.pelvis.edit = false;
    },
    "physicalExamination.column.text"() {
      this.physicalExamination.column.edit = false;
    },
    "physicalExamination.upperExtremities.text"() {
      this.physicalExamination.upperExtremities.edit = false;
    },
    "physicalExamination.lowerExtremities.text"() {
      this.physicalExamination.lowerExtremities.edit = false;
    },
    "plan.analysis.text"() {
      this.plan.analysis.edit = false;
    },
    "plan.plan.text"() {
      this.plan.plan.edit = false;
    },
  },
  methods: {
    genericData(displayText) {
      return {
        text: "",
        showName: displayText,
        edit: true,
      };
    },
    vitalSignsData() {
      return {
        hour: this.genericData("Hora"), // cspell:disable-line
        heartRate: this.genericData("FC"), // cspell:disable-line
        respiratoryRate: this.genericData("FR"), // cspell:disable-line
        bloodPressure: this.genericData("PA"), // cspell:disable-line
        temperature: this.genericData("Temperatura"), // cspell:disable-line
        oxygenSaturation: this.genericData("Sat. O2"), // cspell:disable-line
        consciousLevel: this.genericData("AVDI"), // cspell:disable-line
        glycemia: this.genericData("Glicemia"), // cspell:disable-line
        pain: this.genericData("Dolor"), // cspell:disable-line
      };
    },
    updateRegistry() {
      this.vitalSignsRegistry.push(this.vitalSigns);
      this.vitalSigns = this.vitalSignsData();
      this.VitalSignsView = false;
    },
    deleteLocalStorage() {
      localStorage.removeItem(`basicInfo-${this.id}`);
      localStorage.removeItem(`scene-${this.id}`);
      localStorage.removeItem(`primaryAssessment-${this.id}`);
      localStorage.removeItem(`sample-${this.id}`);
      localStorage.removeItem(`vitalSigns-${this.id}`);
      localStorage.removeItem(`vitalSignsRegistry-${this.id}`);
      localStorage.removeItem(`physicalExamination-${this.id}`);
      localStorage.removeItem(`plan-${this.id}`);
      this.$parent.deleteSoap(this.id);
      location.reload();
    },
    genericTableData(object) {
      let array = [];
      for (const value of Object.values(object)) {
        array.push([value.showName, value.text]);
      }
      return array;
    },
    createDocument() {
      let basicInfo = this.genericTableData(this.basicInfo);
      let scene = this.genericTableData(this.scene);
      let primaryAssessment = this.genericTableData(this.primaryAssessment);
      let sample = this.genericTableData(this.sample);
      // let vitalSignsRegistry = {};
      let physicalExamination = this.genericTableData(this.physicalExamination);
      let plan = this.genericTableData(this.plan);

      var doc = new jsPDF();

      doc.autoTable({
        body: basicInfo,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        body: scene,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        body: primaryAssessment,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        body: sample,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        html: "#vitalSignRegistry",
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        body: physicalExamination,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.autoTable({
        body: plan,
        theme: "striped",
        styles: { lineWidth: 0.3 },
      });
      doc.save(`soap-${this.basicInfo.name.text}.pdf`);
    },
  },
  beforeMount() {
    if (localStorage.getItem(`basicInfo-${this.id}`)) {
      this.basicInfo = JSON.parse(localStorage.getItem(`basicInfo-${this.id}`));
    }
    if (localStorage.getItem(`scene-${this.id}`)) {
      this.scene = JSON.parse(localStorage.getItem(`scene-${this.id}`));
    }
    if (localStorage.getItem(`primaryAssessment-${this.id}`)) {
      this.primaryAssessment = JSON.parse(
        localStorage.getItem(`primaryAssessment-${this.id}`)
      );
    }
    if (localStorage.getItem(`sample-${this.id}`)) {
      this.sample = JSON.parse(localStorage.getItem(`sample-${this.id}`));
    }
    if (localStorage.getItem(`vitalSigns-${this.id}`)) {
      this.vitalSigns = JSON.parse(
        localStorage.getItem(`vitalSigns-${this.id}`)
      );
    }
    if (localStorage.getItem(`vitalSignsRegistry-${this.id}`)) {
      this.vitalSignsRegistry = JSON.parse(
        localStorage.getItem(`vitalSignsRegistry-${this.id}`)
      );
    }
    if (localStorage.getItem(`physicalExamination-${this.id}`)) {
      this.physicalExamination = JSON.parse(
        localStorage.getItem(`physicalExamination-${this.id}`)
      );
    }
    if (localStorage.getItem(`plan-${this.id}`)) {
      this.plan = JSON.parse(localStorage.getItem(`plan-${this.id}`));
    }
  },
};
</script>
