<template>
  <div class="bg-stone-50 flex flex-col">
    <div
    class="border-solid border-2 border-stone-800 rounded-md my-5 p-4 mx-10"
    >
      <!-- cspell:disable-next-line -->
      <p class="mb-5 text-center font-bold">Selecciona la ficha</p>

      <select 
        v-model="selectedSoap" 
        id="soapSelect"
        class="appearance-none w-auto bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      >
        <!-- cspell:disable-next-line -->
        <option disabled value="">Selecciona un paciente</option>
        <option v-for="(item, index) in soaps" :key="index" :value="parseInt(index)">{{ item.name }}</option>
      </select>

      <button
        @click="newSoap()"
        class="bg-blue-300 hover:bg-blue-400 text-sm text-black font-semibold py-2 px-2 m-2 rounded"
      >
        <!-- cspell:disable-next-line -->
        Agregar paciente
      </button>
    </div>
  </div>

  <SoapForm
    v-for="soap in soaps" :key="soap"
    v-show="soap.show"
    :id="soap.soapId"
    v-model:name="soap.name"
  />

  <footer class="bg-stone-50">
    <span class="svg-icon svg-icon-primary svg-icon-2x flex items-center justify-center"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo5/dist/../src/media/svg/icons/Communication/Mail.svg-->
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" x="0" y="0" version="1.1">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24"/>
          <path d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000"/>
        </g>
      </svg><!--end::Svg Icon-->
      <p class="max-w-max">benjastraub@gmail.com</p>
    </span>
  </footer>
</template>

<script>
  import SoapForm from './SoapForm.vue'
  
  export default {
    name: 'SoapManager',
    components: {
      SoapForm
    },
    data() {
      return {
        soaps: [{name: "Paciente 1", show: true, soapId: 0}], // cspell:disable-line
        selectedSoap: 0,
        nextSoap: 1,
      }
    },
    watch: {
      soaps: {
        handler() {
          localStorage.setItem(
            `soaps`,
            JSON.stringify(this.soaps)
          );
        },
        deep: true,
      },
      selectedSoap: {
        handler(newValue, oldValue) {
          if (this.soaps[oldValue]) {
            this.soaps[oldValue].show = false;
          }
          if (this.soaps[newValue]) {
            this.soaps[newValue].show = true;
          } else {
            this.soaps[0].show = true;
          }
          localStorage.setItem(
            `selectedSoap`,
            JSON.stringify(this.selectedSoap)
          );
        }
      },
      nextSoap: {
        handler() {
          localStorage.setItem(
            `nextSoap`,
            JSON.stringify(this.nextSoap)
          );
        },
      },
    },
    methods: {
      newSoap() {
        this.soaps[this.selectedSoap].show=false;
        this.soaps.push({name: `Paciente ${this.soaps.length + 1}`, show: true, soapId: this.nextSoap}); // cspell:disable-line
        this.selectedSoap = this.soaps.length - 1;
        this.nextSoap++;
      },
      deleteSoap(id) {
        const index = this.soaps.findIndex(soap => soap.soapId == id)
        this.soaps.splice(index, 1);
        if (this.soaps.length != 0) {
          this.soaps[0].show = true;
          this.selectedSoap = 0;
        }
        else {
          this.soaps = [{name: "Paciente 1", show: true, soapId: 0}]; // cspell:disable-line
          this.selectedSoap = 0;
          this.nextSoap = 1;
        }
        localStorage.setItem(`soaps`, JSON.stringify(this.soaps));
      }
    },
    beforeMount() {
      if (localStorage.getItem(`soaps`)) {
        this.soaps = JSON.parse(localStorage.getItem(`soaps`));
      } else {
        localStorage.setItem(
          `soaps`,
          JSON.stringify(this.soaps)
        );
      }
      if (localStorage.getItem(`selectedSoap`)) {
        this.selectedSoap = parseInt(localStorage.getItem(`selectedSoap`));
      } else {
        localStorage.setItem(
          `selectedSoap`,
          JSON.stringify(this.selectedSoap)
        );
      }
      if (localStorage.getItem(`nextSoap`)) {
        this.localStorage = parseInt(localStorage.getItem(`nextSoap`));
      } else {
        localStorage.setItem(
          `nextSoap`,
          JSON.stringify(this.nextSoap)
        );
      }
    },
  }
</script>